import React, { useState, useEffect } from "react";
import "../styles/FadeInFromRight.css"; // CSS file for animation styles

const FadeInFromRight = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set isVisible to true after a delay (e.g., 500ms)
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`fade-in-right ${isVisible ? "visible" : ""}`}>
      {children}
    </div>
  );
};

export default FadeInFromRight;
