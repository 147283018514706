import "../styles/sidebar.css";
import { slide as Menu } from "react-burger-menu";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  const [isOpen, setOpen] = useState(false);

  function handleStateChange(state) {
    setOpen(state.isOpen);
  }

  function closeMenu() {
    setOpen(false);
  }
  return (
    <div>
      <Menu isOpen={isOpen} onStateChange={(state) => handleStateChange(state)}>
        <Link className="sideLink" to="about" onClick={closeMenu}>
          ABOUT
        </Link>
        <Link className="sideLink" to="resume" onClick={closeMenu}>
          RESUME
        </Link>
        <Link className="sideLink" to="portfolio" onClick={closeMenu}>
          PORTFOLIO
        </Link>
        <Link className="sideLink" to="contact" onClick={closeMenu}>
          CONTACT
        </Link>
      </Menu>
    </div>
  );
}

export default Sidebar;
