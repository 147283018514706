function PortfolioGrid() {
  const data = [
    {
      // href: "https://www.myflooringguynow.com",
      src: "flooring.png",
      caption: "Client Flooring Site",
      subcaption: "(stay tuned, still in production!)",
    },
    {
      href: "https://github.com/gbs278/flaskchat-app",
      src: "chat.png",
      caption: "Chat Application",
      subcaption: "(Flask-SocketIO x PostgreSQL)",
    },
    {
      href: "/tic-tac-toe",
      src: "tictactoe.png",
      caption: "Tic Tac Toe",
    },
    {
      href: "https://github.com/gbs278/goals-fastapi",
      src: "GoalsPic.png",
      caption: "Basic Goals Application",
      subcaption: "(FastApi x React.js x MongoDB)",
    },
    {
      href: "https://github.com/gbs278/bioverse-helpdesk",
      src: "helpdesk.png",
      caption: "User Help Desk Application",
      subcaption: "(NextJS x FastAPI x PostgreSQL)",
    },
    // {
    //   href: 'https://www.bakedbymelissa.com/menu/tie-dye.html',
    //   src: 'bbmflavor.png',
    //   caption: 'Baked By Melissa Cupcake Info Page',
    // },
    // {
    //   href: 'https://www.bakedbymelissa.com/gifting-and-events',
    //   src: 'bbmgifts.png',
    //   caption: 'Baked By Melissa Corporate Gifts Page',
    // },
    // {
    //   href: 'https://www.bakedbymelissa.com/jobs',
    //   src: 'bbmjobs.png',
    //   caption: 'Baked By Melissa Jobs Page',
    // }
  ];

  return (
    <ul className="grid-container2">
      {data.map((item, num) => {
        return (
          <li key={num} className="grid-item2">
            <a href={item.href} target="_blank">
              <img src={item.src}></img>
            </a>
            <a href={item.href} target="_blank">
              {item.caption}
              <br></br>
              {item.subcaption}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default PortfolioGrid;
