import Tab from "./tab.js";
import Tabs from "./tabs.js";
import "../styles/resume.css";
import { Helmet } from "react-helmet";
import EducationGrid from "./resume/education-grid.js";
import ExperienceMap from "./resume/experience-map.js";
import { Outlet } from "react-router-dom"; // Import Outlet
import SkillSet from "./resume/skillset.js";
import FadeInFromRight from "./FadeInFromRight";
// Import Outlet

function Resume() {
  return (
    <div className="main">
      <Tabs>
        <Tab title="Education">
          <Helmet>
            <title>Education - Gal Ben-Shushan</title>
            <meta name="Education" content="Education" />
          </Helmet>
          <FadeInFromRight>
            <div id="education">
              <div id="eduImage">
                <img src="nyu3.png" alt="Education" />
              </div>
              <EducationGrid />
            </div>
          </FadeInFromRight>
        </Tab>

        <Tab title="Professional Experience">
          <Helmet>
            <title>Experience - Gal Ben-Shushan</title>
            <meta name="Experience" content="Experience" />
          </Helmet>
          <FadeInFromRight>
            <div id="experience">
              <ExperienceMap />
            </div>
          </FadeInFromRight>
        </Tab>

        <Tab title="Skillset">
          <Helmet>
            <title>Skillset - Gal Ben-Shushan</title>
            <meta name="Skillset" content="Skillset" />
          </Helmet>
          <SkillSet />
        </Tab>
      </Tabs>

      {/* Use <Outlet> to render nested routes */}
      <Outlet />
    </div>
  );
}

export default Resume;
