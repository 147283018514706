import React from "react";
import "../styles/about.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faSignature,
  faMapPin,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import FadeInFromRight from "./FadeInFromRight";

function About() {
  return (
    <FadeInFromRight>
      <div id="about">
        <Helmet>
          <title>About - Gal Ben-Shushan</title>
          <meta name="About" content="About" />
        </Helmet>
        <div className="aboutFlex">
          <div
            style={{ textAlign: "center" }}
            className="aboutContent animate__animated animate__fadeInBottomRight animate__slow"
          >
            <h2>ABOUT GAL</h2>
            <div style={{ textAlign: "left", display: "inline-block" }}>
              <p>
                <p>
                  Hey, I'm Gal. I'm a recent NYU grad with a degree in Computer
                  Science. College was a journey of exploration until I found my
                  true passion in the dynamic world of computer science. I'm
                  fascinated by the evolving tech landscape, seeing it as an
                  intricate puzzle with endless possibilities. Coding isn't just
                  lines; it's like magic, blending intuition, theory, finesse,
                  and creativity. What excites me most is how these
                  technological creations simplify lives – that's the real
                  magic.
                </p>
                <p>
                  I'm not your typical tech enthusiast; I thrive on connections
                  and conversations, infusing a bit of humor into the serious
                  tech world. So, if you're curious about my{" "}
                  <span>
                    <a href="/portfolio">past experiences</a>{" "}
                  </span>{" "}
                  and the{" "}
                  <span>
                    <a href="/resume">skills</a>
                  </span>{" "}
                  I've honed along the way, feel free to take a look. Whether
                  you're interested in what I can bring to the table or just
                  fancy a friendly chat, don't hesitate to shoot me a message.
                  Let's connect and see where our paths may lead!
                </p>
              </p>
            </div>
          </div>
          <div
            style={{ textAlign: "center" }}
            className="aboutPicture animate__animated animate__fadeInTopLeft animate__slow"
          >
            <img
              src="galHeadshot.png"
              alt="My photo"
              style={{ marginTop: "20px" }}
            />
            <div style={{ textAlign: "left", display: "inline-block" }}>
              <p>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon
                    style={{ width: "4em" }}
                    icon={faSignature}
                  />
                </span>
                Gal Ben-Shushan
              </p>
              <p>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon
                    style={{ width: "4em" }}
                    icon={faBirthdayCake}
                  />
                </span>
                October 5th, 1999
              </p>
              <p>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon style={{ width: "4em" }} icon={faMapPin} />
                </span>
                New York, NY
              </p>
              <a href="mailto: galbshushan@gmail.com">
                <p>
                  <span style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon
                      style={{ width: "4em" }}
                      icon={faEnvelope}
                    />
                  </span>
                  galbshushan@gmail.com
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="mobileAbout">
          <div style={{ textAlign: "center" }} className="aboutPicture2">
            <h2>ABOUT GAL</h2>
            <img src="galHeadshot.png" className="img-fluid" alt="My photo" />
          </div>
          <div style={{ textAlign: "center" }}>
            <div style={{ textAlign: "left", display: "inline-block" }}>
              <p>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon
                    style={{ width: "4em" }}
                    icon={faSignature}
                  />
                </span>
                Gal Ben-Shushan
              </p>
              <p>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon
                    style={{ width: "4em" }}
                    icon={faBirthdayCake}
                  />
                </span>
                October 5th, 1999
              </p>
              <p>
                <span style={{ marginRight: "10px" }}>
                  <FontAwesomeIcon style={{ width: "4em" }} icon={faMapPin} />
                </span>
                New York, NY
              </p>
              <a href="mailto: galbshushan@gmail.com">
                <p>
                  <span style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon
                      style={{ width: "4em" }}
                      icon={faEnvelope}
                    />
                  </span>
                  galbshushan@gmail.com
                </p>
              </a>
            </div>
          </div>
          <div style={{ textAlign: "center" }} className="aboutContent2">
            <div style={{ textAlign: "left", display: "inline-block" }}>
              <p>
                <p>
                  Hey, I'm Gal. I'm a recent NYU grad with a degree in Computer
                  Science. College was a journey of exploration until I found my
                  true passion in the dynamic world of computer science. I'm
                  fascinated by the evolving tech landscape, seeing it as an
                  intricate puzzle with endless possibilities. Coding isn't just
                  lines; it's like magic, blending intuition, theory, finesse,
                  and creativity. What excites me most is how these
                  technological creations simplify lives – that's the real
                  magic.
                </p>
                <p>
                  I'm not your typical tech enthusiast; I thrive on connections
                  and conversations, infusing a bit of humor into the serious
                  tech world. So, if you're curious about my{" "}
                  <span>
                    <a href="/portfolio">past experiences</a>{" "}
                  </span>{" "}
                  and the{" "}
                  <span>
                    <a href="/resume">skills</a>
                  </span>{" "}
                  I've honed along the way, feel free to take a look. Whether
                  you're interested in what I can bring to the table or just
                  fancy a friendly chat, don't hesitate to shoot me a message.
                  Let's connect and see where our paths may lead!
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </FadeInFromRight>
  );
}

export default About;
