import React, { useCallback } from "react";

function TabTitle(props) {
  var index = props.index;
  var title = props.title;
  var setSelectedTab = props.setSelectedTab;
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <li className="tab-item animate__animated animate__slideInDown">
      <button className={"tab-button " + props.class} onClick={onClick}>
        {title}
      </button>
    </li>
  );
}

export default TabTitle;
