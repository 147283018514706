import React from "react";
import "../../styles/resume.css";

function SkillSet() {
  return (
    <div id="skillset">
      <div className="bar-graph bar-graph-horizontal bar-graph-one">
        <p id="coursesHeader">Skills and Experience (in years)</p>

        <div className="holder bar-four">
          <span className="year">Java</span>
          <div className="bar" data-percentage="4"></div>
        </div>

        <div className="holder bar-four">
          <span className="year">Pyton</span>
          <div className="bar" data-percentage="4"></div>
        </div>

        <div className="holder bar-three">
          <span className="year">React.js</span>
          <div className="bar" data-percentage="3"></div>
        </div>

        <div className="holder bar-one">
          <span className="year">FastAPI</span>
          <div className="bar" data-percentage="1"></div>
        </div>

        <div className="holder bar-two">
          <span className="year">HTML</span>
          <div className="bar" data-percentage="2"></div>
        </div>
        <div className="holder bar-three">
          <span className="year">Linux</span>
          <div className="bar" data-percentage="3"></div>
        </div>
        <div className="holder bar-three">
          <span className="year">JavaScript</span>
          <div className="bar" data-percentage="3"></div>
        </div>
        <div className="holder bar-one">
          <span className="year">Postgres</span>
          <div className="bar" data-percentage="1"></div>
        </div>
        <div className="holder bar-three">
          <span className="year">GitHub</span>
          <div className="bar" data-percentage="3"></div>
        </div>
        <div className="holder bar-one">
          <span className="year">Docker</span>
          <div className="bar" data-percentage="1"></div>
        </div>

        <div className="holder bar-one">
          <span className="year">SQLAlchemy</span>
          <div className="bar" data-percentage="1"></div>
        </div>
      </div>

      <div id="courses">
        <p id="coursesHeader2">Courses Taken</p>

        <ul id="courseList">
          <li>Agile Software Development</li>
          <li>Applied Internet Technology</li>
          <li>Basic Algorithms</li>
          <li>Computer Systems Organization</li>
          <li>Data Management and Analysis</li>
          <li>Data Structures</li>
          <li>Inroduction to Computer Science</li>
          <li>Inroduction to Computer Programming</li>
        </ul>
      </div>
    </div>
  );
}

export default SkillSet;
