import React from "react";
import styled from "styled-components";
import TicTacToe from "./TicTacToe";

function Main() {
  return (
      <Wrapper>
        <TicTacToe />
      </Wrapper>

  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export default Main;
