import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/home.css";
import { Helmet } from "react-helmet";
import FadeInFromRight from "./FadeInFromRight";
import TypingComponent from "./TypingComponent";
const Display = ({ text }) => {
  const typingSpeed = 200;
  return (
    <div>
      <h1
        style={{
          textShadow: "1px 1px 2px rgba(255, 255, 255, 255)",
          fontSize: "36px",
        }}
      >
        <TypingComponent text={text} speed={typingSpeed} />
      </h1>
    </div>
  );
};

function Home() {
  return (
    <FadeInFromRight>
      <div>
        <Helmet>
          <title>Home - Gal Ben-Shushan</title>
          <meta name="Home" content="Home" />
        </Helmet>
        <div className="home">
          <div className="photogrid animate__animated animate__slideInLeft animate__slow">
            <img src="GalCollage.png"></img>
          </div>
          <div className="home-content animate__animated animate__slideInLeft animate__slow">
            <span classname="Typewriter">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h1 className="wave">👋</h1>
                <h1 className="wave">👋</h1>
                <h1 className="wave" style={{ marginRight: "10px" }}>
                  👋
                </h1>
                <Display text={"I'M GAL"} />
              </div>
            </span>
            <p>I have a passion for building out websites and projects.</p>
            <p>
              I built this website using the React.js framework and utilizing
              the different libraries available. With my formal computer science
              education and my self-taught abilities, I exhibit a solid work
              ethic and discipline of grit. Feel free to look around, and don't
              hesitate to reach out with any questions! 😊
            </p>
          </div>
        </div>
      </div>
    </FadeInFromRight>
  );
}

export default Home;
