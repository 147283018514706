import "../styles/contact.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";

const Contact = (props) => {
  // console.log(props);
  // function that takes boolean as param to conditionally display popup
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const { setPopUp } = props;
  let myClass;
  //   if (props.myClass == undefined){
  //        myClass = 'showing'
  //   }

  const hideLightBox = () => {
    setPopUp(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const [status, setStatus] = useState("Send Message");
  const [errors, setErrors] = useState({ name: "E", email: "E", message: "E" });

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const myChangeHandler = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const myErrors = {};
    switch (name) {
      case "name":
        myErrors.fname =
          value.replace(/\s/g, "").length < 1 ? "Cannot leave name blank!" : "";
        setErrors((prevState) => {
          return { ...prevState, name: myErrors.fname };
        });
        break;
      case "email":
        if (value.length < 1) {
          myErrors.email =
            value.replace(/\s/g, "").length < 1
              ? "Cannot leave email blank!"
              : "";
        } else {
          myErrors.email = validEmailRegex.test(value)
            ? ""
            : "Email is not valid!";
        }

        setErrors((prevState) => {
          return { ...prevState, email: myErrors.email };
        });
        break;
      case "message":
        myErrors.message =
          value.replace(/\s/g, "").length < 1
            ? "Cannot send blank message!"
            : "";

        setErrors((prevState) => {
          return { ...prevState, message: myErrors.message };
        });
        break;
    }
    // console.log(errors);
  };

  // const validateForm = (errors) => {
  //   let valid = true;
  //   Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  //   return valid;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const myErrors = {};
    myErrors.fname =
      document.getElementById("name").value.replace(/\s/g, "").length < 1
        ? "Cannot leave name blank!"
        : "";
    setErrors((prevState) => {
      return { ...prevState, name: myErrors.fname };
    });

    if (document.getElementById("email").value.length <= 1) {
      myErrors.email =
        document.getElementById("email").value.replace(/\s/g, "").length < 1
          ? "Cannot leave email blank!"
          : "";
    } else {
      myErrors.email = validEmailRegex.test(
        document.getElementById("email").value
      )
        ? ""
        : "Email is not valid!";
    }

    setErrors((prevState) => {
      return { ...prevState, email: myErrors.email };
    });

    myErrors.message =
      document.getElementById("message").value.replace(/\s/g, "").length < 1
        ? "Cannot send blank message!"
        : "";

    setErrors((prevState) => {
      return { ...prevState, message: myErrors.message };
    });

    if (
      errors.email.length == 0 &&
      errors.name.length == 0 &&
      errors.message.length == 0
    ) {
      // console.info('Valid Form');
      setStatus("Sending...");
      const { name, email, message } = e.target.elements;
      let details = {
        from_name: name.value,
        reply_to: email.value,
        message: message.value,
      };
      // <YOUR_SERVICE_ID>','<YOUR_TEMPLATE_ID>', templateParams, '<YOUR_PUBLIC_KEY></YOUR_PUBLIC_KEY>
      emailjs.send(serviceId, templateId, details, publicKey).then(
        (result) => {
          console.log("sent!", { details });
        },
        (result) => {},
        (error) => {}
      );

      // let response = await fetch('/contact', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json;charset=utf-8',
      //   },
      //   body: JSON.stringify(details),
      // });
      setStatus("Submit");
      // let result = await response.json();
      document
        .getElementsByClassName("pu-content-container")[0]
        .classList.add("sent");
      togglePopup();
      //hideLightBox();

      // alert(result.status);
    } else {
      console.error("Invalid Form");
    }
    // setStatus('Sending...');
    // if (errors.email.length > 0 || errors.name.length > 0 || errors.message.length > 0) {
    //   setStatus('Error Sending');
    //   setInterval(setStatus('Send Message'), 10000);
    // } else {
    //   const { name, email, message } = e.target.elements;
    //   let details = {
    //     name: name.value,
    //     email: email.value,
    //     message: message.value,
    //   };
    //   let response = await fetch('/contact', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json;charset=utf-8',
    //     },
    //     body: JSON.stringify(details),
    //   });
    //   setStatus('Submit');
    //   let result = await response.json();
    //   hideLightBox();
    //   // alert(result.status);
    // }
  };

  return (
    <div className="modalBox">
      <Helmet>
        <title>Contact - Gal Ben-Shushan</title>
        <meta name="Contact" content="Contact" />
      </Helmet>
      <div
        id="popUp"
        className={
          myClass + " PopUp animate__animated animate__fadeIn animate__slow"
        }
      >
        {/* x close window */}
        <a id="contactX" onClick={hideLightBox}>
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <div className="pu-content-container">
          <center>
            <h1>HIT ME UP</h1>
            <p>
              Want to learn more about Gal? Have any inquiries about potential
              projects? Shoot me a message!
            </p>
          </center>

          <form onSubmit={handleSubmit}>
            <div className="form-item">
              <label for="name">Your Name</label>
              <input
                className={
                  errors.name.length > 1 ? "errorBorder" : "normalBorder"
                }
                type="text"
                id="name"
                name="name"
                // onClick={myChangeHandler}
                onBlur={myChangeHandler}
                onChange={myChangeHandler}
              />
              {errors.name.length > 1 ? (
                <span className="error">{errors.name}</span>
              ) : null}
            </div>

            <div className="form-item">
              <label for="email">
                <span>Email</span>
                <input
                  type="text"
                  name="email"
                  className={
                    errors.email.length > 1 ? "errorBorder" : "normalBorder"
                  }
                  id="email"
                  // onClick={myChangeHandler}
                  onBlur={myChangeHandler}
                  onChange={myChangeHandler}
                />
              </label>
              {errors.email.length > 1 ? (
                <span className="error">{errors.email}</span>
              ) : null}
            </div>

            <div className="form-item">
              <label for="message">
                <span>Message</span>
                <textarea
                  className={
                    errors.message.length > 1 ? "errorBorder" : "normalBorder"
                  }
                  // onClick={myChangeHandler}
                  onBlur={myChangeHandler}
                  onChange={myChangeHandler}
                  name="message"
                  id="message"
                />
              </label>
              {errors.message.length > 1 ? (
                <span className="error">{errors.message}</span>
              ) : null}
            </div>

            <input type="submit" value={status} id="contactSubmit" />
          </form>
        </div>

        <div>
          {isOpen ? (
            <div className="pu-content-container">
              <h1>Message Sent!</h1>
              <p>
                Thanks for contacting me! I will make sure to get back to you as
                soon as I can.
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
