function EducationGrid() {
  const eduData = {
    Location: "New York, NY",
    "Attended From": "09/2018 – 05/2022",
    Major: "Computer Science",
    Minor: "Business Studies",
    Degree: "Bachelor of Arts",
    GPA: "3.5",
    "Chabad House Bowery": "President",
    "Tamid Group at NYU": "Member",
    "Alpha Epsilon Pi Fraternity": "Member",
  };

  return (
    <ul className="grid-container">
      {Object.keys(eduData).map((item, index) => {
        return (
          <li key={index} className="grid-item">
            <span>{item}</span>
            <hr></hr>
            {eduData[item]}
          </li>
        );
      })}
    </ul>
  );
}

export default EducationGrid;
