// export const data = [
//   {
//     company: 'Infor',
//     location: 'New York, NY',
//     date: '09/2021 – Present',
//     position: 'Software Engineer, Associate',
//     description:
//     'Develop reusable, dynamic components for customer using jQuery and HTML. ' +
//     'Build out features, as well as resolve bugs, for a new software product using' +
//     ' the React & Angular frameworks in TypeScript. Create metadata collector with GoLang that write to AWS DynamoDB.',
//   },
//   {
//     company: 'MSG Networks',
//     location: 'New York, NY',
//     date: '10/2020 – 05/2021',
//     position: 'Website/Digital Student Associate',
//     description:
//       'Backlogged metadata from Twitter accounts. QA the website every day. Updated content on the corporate' +
//       ' website. Researched other websites for potential features to implement on our own site. Ran an email' +
//       ' marketing campaign. Prepared in-game modules for the mobile app. Created visual assets via Photoshop for' +
//       ' use on social media. ',
//   },
//   {
//     company: 'NYU',
//     location: 'New York, NY',
//     date: '09/2020 – 12/2020',
//     position: 'Tutor – Web Development (JavaScript)',
//     description:
//       'Assisted the students with their assignments. Attended lectures and answered questions students may have.',
//   },
//   {
//     company: 'Daebakd',
//     location: 'New York, NY',
//     date: '05/2020 – 09/2020',
//     position: 'Front End Web Development Intern',
//     description:
//       'Worked extensively with designer and back-end web developer to build out the front-end designs in HTML,' +
//       ' CSS, and JavaScript given for a new social media website.',
//   },
//   {
//     company: 'Baked by Melissa',
//     location: 'New York, NY',
//     date: '06/2019 – 08/2019',
//     position: 'IT & Digital Intern',
//     description:
//       'Assembled content pages for the new website using HTML and CSS programming languages to ensure maximum' +
//       ' quality displaying the information, along with the responsiveness of the pages for mobile devices. This' +
//       ' was done to better the direct to consumer and business to business aspects of the website.',
//   },
//   {
//     company: 'Law Office of Richard Hochhauser, PLLC',
//     location: 'New York, NY',
//     date: '07/2018 – 08/2018',
//     position: 'IT & Web Assistant',
//     description:
//       'Contributed to Data Architecture Paradigms. Strategized move from legacy system to a cloud-based' +
//       ' infrastructure.',
//   },
//   {
//     company: 'Swag.com',
//     location: 'New York, NY',
//     date: '02/2018 – 03/2018',
//     position: 'Product Management Intern',
//     description: 'Contributed to Pricing Algorithm. Cataloged and implemented a backlog for product database.',
//   },
// ];
export const data = [
  {
    company: "Bank of America",
    location: "New York, NY",
    date: "10/2022 – 06/2023",
    position: "Software Engineer",
    description:
      "Collaborated with 15+ quality assurance engineers daily, ensuring Oracle SQL and MySQL databases were up-to-date and accurately reporting customer trade data. Created 50+ Groovy-based test cases weekly in efforts to identify the trade system’s ability to discover database errors, significantly reducing the risk of inaccurate trading data reports. Developed 100+ SQL queries weekly, utilizing advanced techniques such as joins, unions, and nested queries, to ensure that database changes were correctly implemented. Designed 5 automated scripts weekly, using Java and Python, that determine various root causes for failing test cases; automated scripts saved 10+ hours of manual work and allowed for quick identification of programming errors.",
  },
  {
    company: "WeWork",
    location: "New York, NY",
    date: "06/2021 – 06/2022",
    position: "Software Engineer",
    description:
      "Assembled a full stack application with React.js and Spring boot to fetch and display transaction details to specific engineers. Utilized Spring boot classes to paginate detailed information which assisted in organization and efficiency of database use. Implemented security by integrating authorization through the Auth0 library which assisted in the verification of employees. Created React routers to easily access specific customer transactions through application endpoints.",
  },
  {
    company: "GRSEE Consulting",
    location: "Remote",
    date: "06/2020 – 08/2020",
    position: "Python Developer Intern",
    description:
      "Integrated Slack with Mac, Linux, and Windows resulting in increased efficiency in the remote environment. Engineered a Python program that identified and reported network vulnerabilities to customers.",
  },
  {
    company: "Chabad House Bowery",
    location: "New York, NY",
    date: "12/2019 – 12/2020",
    position: "President",
    description:
      "Led a 10-person Executive Board to manage and inspire a community of 300+ Jewish students. Coordinated 8+ events per semester averaging an attendance of 100 students from NYU, Baruch, and Hunter. Oversaw relations between student leadership, Chabad Staff, and community members, meeting with members weekly. Spearheaded a fundraising campaign during COVID-19 raising over $300k via 390+ donors for student activity funds.",
  },
  {
    company: "TAMID Group at NYU",
    location: "New York, NY",
    date: "09/2020 – 12/2020",
    position: "Software Developer",
    description:
      "Strategized with a team of 6 to develop a Natural Language Processing model for conflict detection in emails. Utilized python toolkits such as PyTorch and Spacy for the purpose of analyzing and simplifying email threads. Structured email files into readable formats, primarily as JSON files, in order to organize the priority of emails presented.",
  },
];
